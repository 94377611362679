.base-main {
  &.el-container {
    display: block;

    & .el-row {
      line-height: 48px;

      &>.el-col {
        &>label {
          line-height: 32px;

          &::after {
            content: ":";
            margin-left: 0.3em;
            font-weight: bold;
          }
        }
      }
    }

    & .el-select {
      width: 100%;
    }

    & .el-card {
      margin-bottom: 30px;

      &>.el-card__header {
        font-size: 1.1em;
        font-weight: bold;
      }
    }

    & .el-table {
      /*min-height: 300px;*/

      & .el-table__header,
      & .el-table__body {
        & .cell {
          text-align: center;
        }
      }

      & .el-table__empty-block {
        min-height: 300px;
      }
    }
  }
}
