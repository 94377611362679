.base-main.el-container {
  display: block;
}

.base-main.el-container .el-row {
  line-height: 48px;
}

.base-main.el-container .el-row > .el-col > label {
  line-height: 32px;
}

.base-main.el-container .el-row > .el-col > label:after {
  content: ":";
  margin-left: .3em;
  font-weight: 700;
}

.base-main.el-container .el-select {
  width: 100%;
}

.base-main.el-container .el-card {
  margin-bottom: 30px;
}

.base-main.el-container .el-card > .el-card__header {
  font-size: 1.1em;
  font-weight: 700;
}

.base-main.el-container .el-table .el-table__header .cell, .base-main.el-container .el-table .el-table__body .cell {
  text-align: center;
}

.base-main.el-container .el-table .el-table__empty-block {
  min-height: 300px;
}

/*# sourceMappingURL=commons.c4e3f856.css.map */
